<template>
    <b-card title="Detailed Cash Day Book ">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Store Name">
                        <v-select placeholder="Select Store Name" :options="storeNameOptions" v-model="storeName"
                            label="storeName"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Account Group Name">
                        <v-select placeholder="Select Account Group Name" :options="accountGroupNameOptions"
                            v-model="accountGroupName" label="accountHeadName"
                            @input="getAccounts(accountGroupName.id)"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Account Name">
                        <v-select placeholder="Select Account Name" :options="accountNameOptions" v-model="accountName"
                            label="accountName"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="From Date">
                        <flat-pickr v-model="fromDate" class="form-control" placeholder="From Date" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="To Date">
                        <flat-pickr v-model="toDate" class="form-control" placeholder="To Date" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-button block variant="primary" class="mt-2"
                        @click="getVendorTransactionDetails">Filter</b-button>
                </b-col>
            </b-row>
        </b-form>
        <hr>
        <div>
            <!-- search input -->
            <div class="custom-search">
                <b-row>
                    <b-col md="4">
                        <h4 >Total Credit Balance: <span class="amountCredit">₹{{ totalCreditBalance }}</span></h4>
                    </b-col>
                    <b-col md="4">
                        <h4 >Total Debit Balance: <span class="amountDebit">₹{{ totalDebitBalance }}</span></h4>
                    </b-col>
                    <b-col md="4">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

            </div>

            <!-- table -->
            <vue-good-table ref="table" :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">

                    <!-- Column: lastModifiedDate -->
                    <span v-if="props.column.field === 'lastModifiedDate'" class="text-nowrap">
                        <span class="text-nowrap">
                            {{ convertTimeStampToDateWithTime(props.row.lastModifiedDate) }}
                            <!-- {{props.row.lastUpdated}} -->
                        </span>
                    </span>

                    <!-- Column: amount -->
                    <span v-else-if="props.column.field === 'amount'" class="text-nowrap">
                        <span v-if="props.row.transactionType=='CREDIT'" class="text-nowrap amountCredit">
                            {{ props.row.amount }}
                        </span>
                        <span v-else-if="props.row.transactionType=='DEBIT'" class="text-nowrap amountDebit">
                            {{ props.row.amount }}
                        </span>
                    </span>

                    <!-- Column: updatedBy -->
                    <span v-else-if="props.column.field === 'updatedBy'" class="text-nowrap">
                        <span class="text-nowrap">
                            {{ props.row.updatedBy.fullName }}
                        </span><br>
                        <span class="text-nowrap">
                            [ {{ props.row.updatedBy.mobileNo }} ]
                        </span>
                    </span>


                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>
</template>
<script>
import {
    BCard, BForm, BCol, BRow, BFormInput, BFormGroup, BButton, BPagination, BFormSelect
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
    components: {
        BCard, BForm, BCol, BRow, BFormInput, BFormGroup, BButton, flatPickr, VueGoodTable, BPagination, BFormSelect, vSelect
    },
    data() {
        return {
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
            accountGroupNameOptions: [],
            accountGroupName: { 'id': 0, 'accountHeadName': '---Select---' },
            accountNameOptions: [],
            accountName: { 'id': 0, 'accountName': '---Select---' },
            fromDate: null,
            toDate: null,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'storeName'
                },
                {
                    label: 'Account Name',
                    field: 'accountName'
                },
                {
                    label: 'Wallet Id',
                    field: 'walletId',
                },
                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                // {
                //     label: 'Currency',
                //     field: 'currency'
                // },
                {
                    label: 'Invoice No',
                    field: 'invoiceNo'
                },
                {
                    label: 'Credit / Debit',
                    field: 'transactionType',
                },
                {
                    label: 'Amount',
                    field: 'amount'
                },
                {
                    label: 'Transaction Status',
                    field: 'transactionStatus'
                },
                {
                    label: 'Payment Mode',
                    field: 'paymentMode'
                },
                {
                    label: 'Payment Description',
                    field: 'paymentDescription'
                },
                {
                    label: 'Transaction Description',
                    field: 'transactionDescription'
                },
                {
                    label: 'Last Updated',
                    field: 'lastModifiedDate',
                },
                {
                    label: 'Last Updated By',
                    field: 'lastModifiedByUserName',
                },
            ],
            rows: [],
            searchTerm: '',
            totalCreditBalance:0,
            totalDebitBalance:0
        }
    },
    methods: {
        convertTimeStampToDate(value) {
            if (value == null) {
                return '';
            } else {
                return moment(value).format('YYYY-MM-DD');
            }
        },
        convertTimeStampToDateWithTime(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },
        getVendorTransactionDetails() {
                let self = this;
                self.totalCreditBalance=0;
                self.totalDebitBalance=0;

                var axios = require('axios');
                var data = '';
                let url='';
                if(self.fromDate!=null && self.toDate!=null){
                    url='/finance/accounts/getDetailedCashBook?storeId='+ self.storeName.id + "&accountGroupId=" + self.accountGroupName.id + "&accountId=" + self.accountName.id + "&fromDate=" + self.convertTimeStampToDate(self.fromDate) + "&toDate=" + self.convertTimeStampToDate(self.toDate);
                }else{
                    url='/finance/accounts/getDetailedCashBook?storeId='+ self.storeName.id + "&accountGroupId=" + self.accountGroupName.id + "&accountId=" + self.accountName.id;
                }
                axios(api.getApi('get', url, data))
                    .then(function (response) {

                        
                        self.rows = response.data;
                        if(!response.data.length>0){
                            self.totalCreditBalance=0;
                            self.totalDebitBalance=0;
                        }
                        for (let index = 0; index < response.data.length; index++) {
                            if(response.data[index].transactionType=='CREDIT'){
                                self.totalCreditBalance+=response.data[index].amount;
                            }else{
                                self.totalDebitBalance+=response.data[index].amount;
                            }                      
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions.push({ 'id': 0, 'storeName': 'All' })
                    for (let index = 0; index < response.data.length; index++) {
                        self.storeNameOptions.push(response.data[index]);

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getVendors() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/finance/vendors', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.vendorNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAccountGroups() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/finance/accountGroups', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.accountGroupNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getAccounts(id) {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/finance/accounts/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.accountNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

    },
    created() {
        // this.fromDate = new Date();
        // this.toDate = new Date();
        this.getStoreName();
        this.getVendors();
        this.getAccountGroups();
        // this.getAccounts();
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.amountCredit {
    color: green;
}

.amountDebit {
    color: red;
}
</style>